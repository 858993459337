import { ReactComponent as Check } from '@jetshop/ui/svg/Check.svg';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { ActiveCarot } from './ActiveCarot';
import { filterName } from './shared/styles';
import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import { useMultiFilter } from '@jetshop/core/hooks/Filters/useMultiFilter';

export const filtersStyle = css`
  list-style: none;
  li button {
    padding: 1em;
    width: 100%;
    display: flex;
    background: transparent;
    border-top: 1px solid white;
    background: #f0f0f0;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 0.75em;
    }
  }
`;

const ActiveItem = styled('span')`
  margin-right: 1rem;
  font-weight: bold;
  text-align: right;
`;
const ActiveItemAndIcon = styled('div')`
  display: flex;
  align-items: center;
`;

const FilterName = styled('div')`
  flex: 1 0 35%;
`;

export const ListAccordion = ({
  filters,
  accordionProps,
  startingIdx = 0,
  onCarBrandChange
}) => {
  if (!filters) return null;

  const listFilters = filters.filter(
    filter => filter.__typename === 'ListFilter' && filter.name !== 'carCode'
  );

  const multiListFilters = filters.filter(
    filter => filter.__typename === 'MultiListFilter'
  );

  return (
    <>
      {multiListFilters.map(filter => (
        <MultiListFilter
          key={filter.id}
          filter={filter}
          accordionProps={accordionProps}
          onCarBrandChange={onCarBrandChange}
        />
      ))}
      {listFilters.map((filter, idx) => (
        <ListFilter
          key={filter.id}
          filter={filter}
          idx={idx}
          startingIdx={startingIdx}
          accordionProps={accordionProps}
          onCarBrandChange={onCarBrandChange}
        />
      ))}
    </>
  );
};

function ListFilter({
  filter,
  idx,
  accordionProps,
  startingIdx,
  onCarBrandChange
}) {
  const { apply } = useListFilter({ filter });

  const { AccordionContent } = accordionProps;
  const accordionIsOpen = accordionProps.openIndexes.includes(
    idx + startingIdx
  );

  const handleCarBrandChange = () => {
    if (onCarBrandChange) {
      onCarBrandChange();
    }
  };

  const activeItem = filter.items.find(i => i.isActive);

  return (
    <>
      <div
        className={filterName}
        onClick={() => {
          accordionProps.handleClick(idx);
          handleCarBrandChange();
        }}
      >
        <FilterName>{filter.name}: </FilterName>
        <ActiveItemAndIcon>
          <ActiveItem>{activeItem && ` ${activeItem.text}`}</ActiveItem>
          <ActiveCarot isOpen={accordionIsOpen} />
        </ActiveItemAndIcon>
      </div>
      <AccordionContent isOpen={accordionIsOpen}>
        <ul className={filtersStyle}>
          {filter.items.map(item => (
            <li key={item.id}>
              <button
                onClick={() => {
                  apply({ value: item.value });
                  handleCarBrandChange();
                  accordionProps.handleClick(idx);
                }}
              >
                {item.text}

                {item.isActive ? <Check /> : <span>{item.resultCount}</span>}
              </button>
            </li>
          ))}
        </ul>
      </AccordionContent>
    </>
  );
}

function MultiListFilter({ filter, accordionProps, onCarBrandChange }) {
  const { apply } = useMultiFilter({ filter });

  const { AccordionContent } = accordionProps;

  const handleCarBrandChange = () => {
    if (onCarBrandChange) {
      onCarBrandChange();
    }
  };

  return (
    <>
      {filter.lists.map((item, index) => {
        const accordionIsOpen = accordionProps.openIndexes.includes(item.id);
        const activeItem = item.items.find(i => i.isActive);
        return (
          <>
            <div
              className={filterName}
              onClick={() => {
                accordionProps.handleClick(item.id);
                handleCarBrandChange();
              }}
            >
              <FilterName>{item.name}: </FilterName>
              <ActiveItemAndIcon>
                <ActiveItem>{activeItem && ` ${activeItem.text}`}</ActiveItem>
                <ActiveCarot isOpen={accordionIsOpen} />
              </ActiveItemAndIcon>
            </div>
            <AccordionContent isOpen={accordionIsOpen}>
              <ul className={filtersStyle}>
                {item.items.map(
                  ({ id, text, value, isActive, resultCount }) => (
                    <li key={id}>
                      <button
                        onClick={() => {
                          apply({ value: value, listIndex: index });
                          handleCarBrandChange();
                          accordionProps.handleClick(item.id);
                        }}
                      >
                        {text}
                        {isActive ? <Check /> : <span>{resultCount}</span>}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </AccordionContent>
          </>
        );
      })}
    </>
  );
}
