import { Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import React from 'react';
import { ActiveFilters } from './ActiveFilters';
import { ListFilters } from './ListFilters';
import ClearButton from './ClearButton';
import { useFilters } from '@jetshop/core/hooks/Filters/useFilters';
import t from '@jetshop/intl';
import { theme } from '../../Theme';
import { MultiListFilters } from './MultiListFilters';

const FiltersWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 20px;
  align-items: center;
`;

const FiltersSectionWrapper = styled('div')`
  display: flex;
  flex-direction: row;

  ${theme.below.md} {
    flex-direction: column;
  }
`;

const FiltersSubSectionWrapper = styled(FiltersWrapper)`
  justify-content: space-between;
`;

const Filter = ({ filters, handleClearFilters }) => {
  const { clearAllFilters } = useFilters();

  const handleClear = () => {
    if (handleClearFilters) {
      handleClearFilters();
    }
    clearAllFilters();
  };

  const handleBrandChange = () => {
    if (handleClearFilters) {
      handleClearFilters();
    }
  };

  return (
    <>
      <Above breakpoint="xl">
        {matches =>
          matches && (
            <>
              <FiltersSectionWrapper>
                <FiltersWrapper>
                  <MultiListFilters
                    filters={filters}
                    onBrandChange={handleBrandChange}
                  />
                  <ListFilters filters={filters} />
                  <ClearButton type="button" onClick={handleClear}>
                    {t('Clear all filters')}
                  </ClearButton>
                </FiltersWrapper>
              </FiltersSectionWrapper>
              <FiltersSubSectionWrapper>
                <ActiveFilters filters={filters} />
              </FiltersSubSectionWrapper>
            </>
          )
        }
      </Above>
    </>
  );
};

export default Filter;
