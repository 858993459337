import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../../Theme';
import { ReactComponent as Hashtag } from '../../../assets/svg/Hashtag.svg';
import SearchField from '../../SearchPage/SearchField';
import SearchCategoryList from './SearchCategoryList';

export const searchFieldStyles = css`
  margin: 0;

  ${theme.below.md} {
    flex: 1;
  }
  &[data-flight-searchfield] > * {
    display: flex;
    height: 100%;
    width: 100% !important;
    justify-content: center;

    input {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      color: ${theme.colors.abdDarkGrey};
      height: 100%;
      width: 100%;
      padding: 0 1rem;
      outline: none;

      ::placeholder {
        text-transform: none;
      }

      ${theme.below.xs} {
        font-size: 16px;
        width: 308px;
      }

      :focus {
        border-color: 'inherit';
      }
    }
  }
  [data-flight-searchfield-cancel] {
    display: none;
  }
  [data-flight-searchfield-flyout] {
    text-align: left;
    top: 100%;

    > * {
      padding: 0.5rem;
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }
    li {
      border-top: 1px solid #e8e8e8;
    }
    a {
      color: black;
      padding: 0.5rem;
      display: block;
    }
  }
`;

const SearchFieldWrapper = styled('div')`
  width: 100%;
  position: relative;
  font-size: 16px;
  font-weight: normal;
`;

const Flyout = styled('div')`
  position: absolute;
  background: white;
  z-index: 999;
  width: 100%;
  top: 2rem;
  left: 0;
`;

const InnerWrapper = styled('div')`
  display: flex;
`;

const searchInput = css`
  height: 50px;
  width: 100%;
  font-family: 'Barlow';
  font-weight: 600;
  font-size: 1rem;
  line-height: 120%;
  border: 1px solid ${theme.colors.lightgrey};
  color: #1a202d;
  padding-left: 10px;
  -webkit-appearance: none;
  border-radius: 0px;
  outline: none;
`;

const SearchIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #062d93;
  min-width: 60px;
  height: 50px;

  svg {
    path {
      stroke: white;
    }
  }
`;

const SearchFieldOriginalParts = ({
  searchField,
  searchInputPlaceholder,
  setSearchTerm,
  carData
}) => {
  return (
    <div className={searchFieldStyles}>
      <InnerWrapper>
        <SearchIconContainer>
          <Hashtag />
        </SearchIconContainer>
        <SearchFieldWrapper>
          <FlyoutTrigger showCover={false} id="searchFlyout">
            {({ showTarget }) => (
              <>
                <input
                  className={searchInput}
                  placeholder={searchInputPlaceholder}
                  {...searchField.getInputProps({
                    onChange: e => setSearchTerm(e.target.value),
                    onFocus: showTarget
                  })}
                  data-flight-search-autocomplete-field=""
                />
              </>
            )}
          </FlyoutTrigger>

          <FlyoutTarget id="searchFlyout">
            {flyout => {
              const hasResults =
                searchField.result?.products?.result.length > 0 ||
                searchField.result?.categories?.result.length > 0;
              const { loading, isDirty, isOpen } = searchField;

              const showFlyout =
                isDirty && !loading && hasResults && isOpen && flyout.isOpen;

              if (showFlyout) {
                return (
                  <Flyout data-flight-searchfield-flyout>
                    <div {...searchField.getFlyoutProps()}>
                      {searchField.isDirty && (
                        <React.Fragment>
                          <SearchCategoryList
                            term={searchField.term}
                            result={searchField.result}
                            onClick={flyout.hideTarget}
                            loading={searchField.loading}
                            carData={carData}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </Flyout>
                );
              }
              return null;
            }}
          </FlyoutTarget>
        </SearchFieldWrapper>
      </InnerWrapper>
    </div>
  );
};

SearchField.Flyout = Flyout;
SearchField.Wrapper = SearchFieldWrapper;

export default SearchFieldOriginalParts;
