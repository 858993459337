import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import SearchAutoCompleteContainer from '../../../components/SearchPage/SearchAutoCompleteContainer';
import { theme } from '../../../components/Theme';
import { getFilterQueryString } from '../../../helpers';
import useRecopartSearch from '../../../hooks/useRecopartSearch';
import Styled from './Style';
import { Above } from '@jetshop/ui/Breakpoints';
import { ReactComponent as SEicon } from '../../../assets/svg/SEicon.svg';
import { ReactComponent as VerificationCheck } from '../../../assets/svg/VerificationCheck.svg';
import { ReactComponent as InputError } from '../../../svg/InputError.svg';
import SearchField from '../../../components/SearchPage/SearchField';
import AutocompleteQuery from '../../../components/Layout/Header/AutocompleteQuery.gql';
import { useHistory, useLocation } from 'react-router';
import { MultiListFilters, useMultiListFilter } from './MultiListFilters';
import { useRegNrContext, useRegNrDispatch } from '../../../contexts/regnr';
import SearchFieldOriginalParts from '../../../components/Forms/SearchFieldOriginalParts/SearchFieldOriginalParts.js';

export const searchFieldStyles = css`
  margin: 0;

  ${theme.below.md} {
    flex: 1;
  }
  &[data-flight-searchfield] > * {
    display: flex;
    height: 100%;
    width: 100% !important;
    justify-content: center;

    input {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      color: ${theme.colors.abdDarkGrey};
      height: 100%;
      width: 100%;
      padding: 0 1rem;
      outline: none;

      ::placeholder {
        text-transform: none;
      }

      ${theme.below.xs} {
        font-size: 16px;
        width: 308px;
      }
    }
  }
  [data-flight-searchfield-cancel] {
    display: none;
  }
  [data-flight-searchfield-flyout] {
    text-align: left;
    top: 100%;

    > * {
      padding: 0.5rem;
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }
    li {
      border-top: 1px solid #e8e8e8;
    }
    a {
      color: black;
      padding: 0.5rem;
      display: block;
    }
  }
`;

const CancelButton = styled('button')`
  font-size: 0.875rem;
  border: 0;
  background: transparent;
  color: #878787;
  margin-left: 1rem;
`;

const ButtonWrapper = styled('div')`
  position: relative;
  background-color: #6cc400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  width: 50%;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 3px;
  }

  :hover,
  :active {
    background-color: ${theme.colors.abdGreenHover} !important;
  }

  button {
    font-size: 0.875rem;
    font-weight: 700 !important;
    text-transform: uppercase;
    color: black;
  }

  .badge {
    position: absolute;
    pointer-events: none;
    color: white;
    font-size: 0.5rem;
  }

  ${theme.below.lg} {
    margin-top: 20px;
  }

  ${theme.below.sm} {
    width: 100%;
    margin-top: 20px;
  }
`;

const Button = styled('button')`
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  padding: 13px 20px 14px 20px;
  width: 100%;

  svg:hover {
    path {
      fill: ${theme.colors.abdGreen} !important;
    }
  }
`;

const InfoText = styled('p')`
  color: white;
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  width: 90%;

  ${theme.below.lg} {
    font-size: 1rem;
  }
`;

const InputInformation = styled(InfoText)`
  margin-top: 8px;
  color: #c4c5c5;

  ${theme.below.md} {
    color: #fff;
  }
`;

const verificationCheckIcon = css`
  width: 40px;
`;

const inputErrorIcon = css`
  width: 40px;
  transform: rotate(-45deg);
`;

const HeroSearchForm = ({
  initialValue,
  className,
  placeholder = '',
  filtersFromLocationState,
  carData,
  regInputPlaceholder,
  searchInputPlaceholder,
  ...rest
}) => {
  const {
    searchString: recoSearchString,
    setSearchString,
    handleSearchStringChange,
    data,
    loading
  } = useRecopartSearch();

  const history = useHistory();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useRegNrDispatch();
  const { id, manufacturer, model } = useRegNrContext();
  const { sendFilterQuery } = useMultiListFilter();

  useEffect(() => {
    const manufacturerItem = manufacturer?.items?.find(
      item => item.value === data?.recopartCarModel?.carCodeGroupName
    );

    if (manufacturerItem) {
      sendFilterQuery({
        multiListFilters: [{ id, values: [manufacturerItem.value] }]
      });
      dispatch({
        type: 'addActiveFilterItem',
        filter: {
          ...manufacturer,
          activeItem: { ...manufacturerItem, label: manufacturerItem.value }
        }
      });
    }
  }, [data?.recopartCarModel]);

  useEffect(() => {
    const modelItem = model?.items?.find(
      item => item.value === data?.recopartCarModel?.carCodeName
    );

    if (modelItem) {
      dispatch({
        type: 'addActiveFilterItem',
        filter: {
          ...model,
          activeItem: { ...modelItem, label: modelItem.value }
        }
      });
    }
  }, [model.items, data?.recopartCarModel]);

  useEffect(() => {
    if (
      manufacturer?.activeItem?.value !==
      data?.recopartCarModel?.carCodeGroupName
    ) {
      setSearchString('');
    }
  }, [manufacturer.activeItem]);

  const handleFormSubmit = (searchField, searchTerm) => {
    const filter =
      manufacturer.activeItem || model.activeItem
        ? getFilterQueryString(2, manufacturer.activeItem, model.activeItem)
        : location.search;

    searchTerm
      ? searchField.triggerSearch(searchTerm)
      : history.push(`/bil?${filter}`);
  };

  return (
    <SearchAutoCompleteContainer
      autocompleteQuery={AutocompleteQuery}
      initialValue={initialValue}
      carData={data?.recopartCarModel}
      manufacturer={manufacturer}
      model={model}
      {...rest}
    >
      {searchField => {
        return (
          <Styled.InputFieldsContainer
            className={className}
            role="search"
            onSubmit={e => {
              e.preventDefault();
              handleFormSubmit(searchField, searchTerm);
            }}
          >
            <Styled.RegNumber>
              <Styled.RegIcon>
                <Above breakpoint="md">
                  {matches => (matches ? <SEicon /> : <SEicon />)}
                </Above>
              </Styled.RegIcon>
              <Styled.RegNr>ABC123</Styled.RegNr>
              <Styled.RegInfoInput
                type="search"
                name="RegInfoInput"
                id="RegInfoInput"
                value={recoSearchString}
                onChange={handleSearchStringChange}
                placeholder={regInputPlaceholder}
                aria-label="Sök efter fordonsinfo med registreringsnummer"
              />
              {data?.recopartCarModel?.nameOfCar && (
                <VerificationCheck className={verificationCheckIcon} />
              )}
              {data?.recopartCarModel?.error && (
                <InputError className={inputErrorIcon} />
              )}
              {loading && <Styled.Spinner />}
            </Styled.RegNumber>
            {data?.recopartCarModel?.nameOfCar && (
              <Styled.MessageContainer>
                {data?.recopartCarModel?.nameOfCar}
              </Styled.MessageContainer>
            )}
            {data?.recopartCarModel?.error && (
              <Styled.MessageContainer>
                {data?.recopartCarModel?.errorMessage ||
                  'Vi kunde inte hitta någon bilmodell'}
              </Styled.MessageContainer>
            )}

            <Styled.FilterListContainer>
              <MultiListFilters />
            </Styled.FilterListContainer>

            <Styled.SearchFieldContainer>
              <SearchFieldOriginalParts
                searchField={searchField}
                searchInputPlaceholder={searchInputPlaceholder}
                setSearchTerm={setSearchTerm}
                carData={data.recopartCarModel}
              />
              <InputInformation>
                Ange artikelnummer i sökfältet för att snabbt hitta rätt
                bildelar för ditt märke, modell och år.
              </InputInformation>
              <ButtonWrapper>
                <Button disabled={loading}>
                  <span>Sök bildelar</span>
                </Button>
              </ButtonWrapper>
            </Styled.SearchFieldContainer>
          </Styled.InputFieldsContainer>
        );
      }}
    </SearchAutoCompleteContainer>
  );
};

SearchField.Cancel = CancelButton;

export default HeroSearchForm;
