import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import debounce from 'lodash.debounce';
import * as React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { callAllEventHandlers } from '@jetshop/ui/utils/callAllEventHandlers';
import { getFilterQueryString } from '../../helpers';

class SearchFieldBehaviour extends React.Component {
  inputRef = React.createRef();
  flyoutRef = React.createRef();

  cleanup = () => {};

  _isMounted = false;

  initialState = {
    isDirty: false,
    term: this.props.initialValue,
    debouncedTerm: this.props.initialValue,
    isOpen: false
  };

  environment = typeof window === 'undefined' ? null : window;

  state = this.initialState;

  defaultProps = {
    initialValue: '',
    focusOnLoad: false
  };

  componentDidMount() {
    this._isMounted = true;
    const targetIsInside = target =>
      [this.flyoutRef, this.inputRef].some(
        node =>
          node &&
          node.current &&
          (node.current === target || node.current.contains(target))
      );

    const onMouseUp = event => {
      if (!targetIsInside(event.target)) {
        this.handleBlur(event);
        this.setState({ isOpen: false });
      }
    };

    const onTouchStart = event => {
      if (!targetIsInside(event.target)) {
        this.handleBlur(event);
        this.setState({ isOpen: false });
      }
    };

    if (this.environment) {
      this.environment.addEventListener('mouseup', onMouseUp);
      this.environment.addEventListener('touchstart', onTouchStart);
    }

    if (this.props.focusOnLoad) {
      this.updateFocus('focus');
    }

    this.cleanup = () => {
      if (this.environment) {
        this.environment.removeEventListener('mouseup', onMouseUp);
        this.environment.removeEventListener('touchstart', onTouchStart);
      }
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.cleanup();
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;
    const locationPath =
      pathname.substr(-1) !== '/'
        ? pathname
        : pathname.substr(0, pathname.length - 1);

    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      locationPath !== this.props.routes.search.path
    ) {
      this.props.onCancel();
    }
  }

  getInputProps = ({
    onBlur,
    onFocus,
    onChange,
    refKey = 'ref',
    ...rest
  } = {}) => {
    const {
      updateSearch,
      handleBlur,
      handleFocus,
      handleEnterSubmit,
      handleButtonClick,
      inputRef
    } = this;
    const { term } = this.state;

    return {
      onChange: event => {
        updateSearch(event);
        onChange && onChange(event);
        handleButtonClick(event);
      },
      onBlur: callAllEventHandlers(onBlur, handleBlur),
      onFocus: callAllEventHandlers(onFocus, handleFocus),
      onKeyDown: callAllEventHandlers(handleEnterSubmit),
      [refKey]: inputRef,
      autoComplete: 'off',
      value: term,
      type: 'search',
      ...rest
      // TODO: add an ID via props or generate one
      // TODO: add ARIA labels
    };
  };

  getFlyoutProps = ({ refKey = 'ref', rest } = {}) => {
    const { flyoutRef } = this;

    return {
      [refKey]: flyoutRef,
      ...rest
    };
  };

  getCancelProps = ({ onClick, ...rest } = {}) => {
    const { clearSearch } = this;
    const { onCancel } = this.props;

    return {
      onClick: (onClick, onCancel, clearSearch),
      ...rest
    };
  };

  resetIfEmpty = e => {
    if (e.currentTarget.value === this.props.initialValue) {
      this.clearSearch();
    }
  };

  handleBlur = (e, cb) => {
    this.resetIfEmpty(e);
    cb && typeof cb === 'function' && cb();
    return true;
  };

  handleEnterSubmit = e => {
    const { term } = this.state;
    if (e.key === 'Enter') {
      this.triggerSearch(term);
    }
  };

  handleButtonClick = event => {
    // Check if the event is a mouse click
    if (event.type === 'click') {
      const { term } = this.state;
      this.triggerSearchAlt(term);
    }
  };

  triggerSearch = (term = this.state.term) => {
    const carModelFilter = getFilterQueryString(
      2,
      this.props.manufacturer?.activeItem,
      this.props.model?.activeItem
    );
    const carModelFilterString = carModelFilter ? `&${carModelFilter}` : '';
    // Blur the input, close the search and navigate to the search page
    const { search } = this.props.location;
    const path = this.props.routes.search.path;
    const replacer = (_, __, p2) => (p2 ? p2 : ''); // Remove the term query param
    const filterQueryString = search
      .replace('?', '&')
      .replace(/(&term=.+?)(&.+)|(&term=.+)/, replacer);

    this.setState({ isOpen: false });
    this.debouncedUpdateTerm.cancel();

    if (typeof this.props.onSubmit === 'function') this.props.onSubmit(term);

    this.props.history.push(
      `${path}/?term=${term}${filterQueryString}${carModelFilterString}`
    );
    this.updateFocus('blur');
  };

  triggerSearchAlt = (term = this.state.term) => {
    const carModelFilter = getFilterQueryString(
      2,
      this.props.manufacturer?.activeItem,
      this.props.model?.activeItem
    );
    const carModelFilterString = carModelFilter ? `&${carModelFilter}` : '';
    // Blur the input, close the search and navigate to the search page
    const { search } = this.props.location;
    const path = this.props.routes.search.path;
    const replacer = (_, __, p2) => (p2 ? p2 : ''); // Remove the term query param
    const filterQueryString = search
      .replace('?', '&')
      .replace(/(&term=.+?)(&.+)|(&term=.+)/, replacer);

    this.setState({ isOpen: false });
    this.debouncedUpdateTerm.cancel();

    if (typeof this.props.onSubmit === 'function') this.props.onSubmit(term);

    this.props.history.push(
      `${path}/?term=${term}${filterQueryString}${carModelFilterString}`
    );
    this.updateFocus('blur');
  };

  handleFocus = (_e, cb) => {
    this.setState({ isOpen: true });
    cb && typeof cb === 'function' && cb();
    return true;
  };

  updateFocus = focusState => {
    if (!['blur', 'focus'].includes(focusState)) {
      throw new Error(
        "updateFocus must be called with one of 'blur' | 'focus'"
      );
    }

    const shouldHaveFocus = focusState === 'focus';

    const DOMElementHasFocus = document.activeElement === this.inputRef.current;

    // Trigger a blur or focus on the input
    shouldHaveFocus && !DOMElementHasFocus && this.inputRef.current.focus();
    !shouldHaveFocus && DOMElementHasFocus && this.inputRef.current.blur();

    return true;
  };

  debouncedUpdateTerm = debounce(
    term => this._isMounted && this.setState({ debouncedTerm: term }),
    200
  );

  updateSearch = e => {
    const term = e.currentTarget.value;

    const isDirty = term !== this.props.initialValue;
    this.debouncedUpdateTerm(term);
    this.setState({
      term,
      isDirty
    });
  };

  clearSearch = () => {
    this.setState(this.initialState);
  };

  render() {
    return (
      <Query
        context={{ useApolloNetworkStatus: false }}
        skip={
          !this.state.isDirty ||
          this.state.debouncedTerm === this.props.initialValue
        }
        variables={{ term: this.state.debouncedTerm }}
        errorPolicy="ignore"
        query={this.props.autocompleteQuery}
      >
        {({ loading, data }) => {
          return this.props.children({
            getInputProps: this.getInputProps,
            getCancelProps: this.getCancelProps,
            getFlyoutProps: this.getFlyoutProps,
            loading: loading || this.state.term !== this.state.debouncedTerm,
            isOpen: this.state.isOpen,
            updateFocus: this.updateFocus,
            result: data?.searchAutoComplete,
            inputRef: this.inputRef,
            triggerSearch: this.triggerSearch,
            triggerSearchAlt: this.triggerSearchAlt,
            handleButtonClick: this.handleButtonClick,
            ...this.state
          });
        }}
      </Query>
    );
  }
}

// If SearchFieldBehaviour is refactored to use hooks, then this can be moved inside there
const SearchFieldBehaviourWrapper = props => {
  const { routes } = useShopConfig();
  return <SearchFieldBehaviour {...props} routes={routes} />;
};

export default withRouter(SearchFieldBehaviourWrapper);
