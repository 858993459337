import t from '@jetshop/intl';
import * as React from 'react';
import { css } from 'linaria';
import { useSortOrder } from '@jetshop/core/hooks/Filters/useSortOrder';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';
import SimpleSelect from '../../ui/SimpleSelect';

const sortText = css`
  box-sizing: content-box;
`;

export const SortOrders = ({ sortOrders }) => {
  if (sortOrders.length === 0) return null;

  return (
    <ErrorBoundary component={PassThrough}>
      <SortOrderDropdown sortOrders={sortOrders} />
    </ErrorBoundary>
  );
};

function SortOrderDropdown({ sortOrders }) {
  const {
    mappedSortOrder,
    changeSortOrder,
    sortOrder,
    direction
  } = useSortOrder({ sortOrders });

  const options = mappedSortOrder.map(item => ({
    ...item,
    label: item.text
  }));

  const activeItem = options.find(
    item => item.direction === direction && item.value === sortOrder
  );

  return (
    <>
      <p className={sortText}>{t('Sort by')}:</p>
      <SimpleSelect
        instanceId={'sortby'}
        options={options}
        value={activeItem}
        placeholder={t('Select...')}
        onChange={item => {
          changeSortOrder(item.value, item.direction);
        }}
      />
    </>
  );
}
