import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { CategoryBrands } from './CategoryBrands';
import { CategoryDescription } from './CategoryDescription';
import SearchRegNrOrProduct from './SearchRegNrOrProduct';

const CategoryImageWrapper = styled('div')`
  position: relative;
  width: 100%;

  ${theme.below.md} {
    height: 217px;
  }
`;

const Overlay = styled('div')`
  background-color: rgba(0, 0, 0, 0.61);
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
`;

export const CategoryHeaderWrapper = styled('div')`
  background-color: #ffffff;
  margin: 0rem 2rem;

  ${Overlay} {
    position: ${props => (props.hasBackgroundImage ? 'absolute' : 'inherit')};
  }

  ${CategoryImageWrapper} {
    height: ${props => (props.hasBackgroundImage ? '416px' : 'auto')};
  }

  ${theme.below.sm} {
    padding: 0rem;
    margin: 0rem 1rem 1rem;
  }
`;

const CategoryHeader = ({
  backgroundImage,
  children,
  description,
  carData
}) => {
  return (
    <CategoryHeaderWrapper hasBackgroundImage={backgroundImage}>
      {<CategoryBrands brands={children} />}
      {<CategoryDescription description={description} />}
      <SearchRegNrOrProduct carData={carData} />
    </CategoryHeaderWrapper>
  );
};

export default CategoryHeader;
