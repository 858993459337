import React from 'react';
import MaxWidth from '../Layout/MaxWidth';
import CarModelSpecification from './CarModelSpecification';
import { theme } from '../Theme';
import { styled } from 'linaria/react';
import { useFiltersFromLocationState } from '../CategoryPage/WindowedCategoryPage';

export const CategoryHeaderWrapper = styled('div')`
  background-color: ${theme.colors.background};

  ${Overlay} {
    position: ${props => (props.hasBackgroundImage ? 'absolute' : 'inherit')};
    background-color: white;
    margin-top: 20px;
  }

  ${CategoryWrapper} {
    height: ${props => (props.hasBackgroundImage ? '416px' : 'auto')};
  }
`;

const CategoryWrapper = styled('div')`
  position: relative;
  width: 100%;
  gap: 0.5rem 1.5rem;

  ${theme.below.md} {
    height: 217px;
  }
`;

const Overlay = styled('div')`
  background-color: rgba(0, 0, 0, 0.61);
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const CategoryHeaderBanner = () => {
  const carData = useFiltersFromLocationState();

  return (
    <CategoryHeaderWrapper>
      <CategoryWrapper>
        <Overlay>
          <MaxWidth>
            {carData && <CarModelSpecification carData={carData} />}
          </MaxWidth>
        </Overlay>
      </CategoryWrapper>
    </CategoryHeaderWrapper>
  );
};

export default CategoryHeaderBanner;
