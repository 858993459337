import t from '@jetshop/intl';
import SuggestedTerm from '@jetshop/ui/Search/SuggestedTerm';
import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { Link } from 'react-router-dom';
import {
  getFilterQueryString,
  getSearchParamsBasedOnCategoryId
} from '../../../helpers';
import { useRegNrContext } from '../../../contexts/regnr';

const resultStyles = css`
  ul + h2 {
    margin-top: 1em;
  }
`;

const CategoryLink = styled(Link)``;

const SearchCategoryList = ({ term, result, loading, onClick, carData }) => {
  const products = result?.products;
  const categories = result?.categories;

  const { manufacturer, model } = useRegNrContext();

  if (
    !loading &&
    products?.result.length === 0 &&
    categories?.result.length === 0
  ) {
    return null;
  }

  return (
    <div className={resultStyles}>
      {products?.result.length > 0 && (
        <>
          <h2>{t('Products')}</h2>
          <ul style={loading ? { opacity: 0.5 } : null}>
            {products?.result?.map(item => (
              <SuggestedTerm
                term={term}
                onClick={onClick}
                key={item.id}
                item={item}
              />
            ))}
          </ul>
        </>
      )}

      {categories?.result.length > 0 && (
        <>
          <h2>{t('Categories')}</h2>
          <ul style={loading ? { opacity: 0.5 } : null}>
            {categories?.result?.map(item => {
              const searchParams = getSearchParamsBasedOnCategoryId(item);

              const filter = getFilterQueryString(
                item.id,
                manufacturer.activeItem,
                model.activeItem
              );

              const carCodeFilterString = `/${
                item.primaryRoute.id
              }${searchParams}${filter ? `?${filter}` : ''}`;

              return (
                <li>
                  <CategoryLink
                    to={{
                      pathname: carCodeFilterString,
                      state: {
                        recopartCarModel: carData
                      }
                    }}
                  >
                    {item.name}
                  </CategoryLink>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default SearchCategoryList;
