import React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { ModalTrigger } from '@jetshop/ui/Modal/ModalTrigger';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { theme } from '../Theme';
import ButtonWithLoading from '../ui/Button';

const CarModelContentWrapper = styled('div')`
  display: flex;
  gap: 0.5rem 1.5rem;
  padding: 0.3125rem;
  padding-left: 0;
  align-items: center;
  flex-wrap: wrap;

  ${theme.above.xl} {
    flex-wrap: wrap;
  }

  ${theme.below.sm} {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem 0rem;
    padding: 0;
    padding-left: 0;
    align-items: flex-start;
  }
`;

const CarModelContent = styled('p')`
  margin-left: 0;
  color: ${theme.colors.black};
  font-size: 1rem;

  ${theme.below.sm} {
    font-size: 0.875rem;
  }
`;

const CloseModalButton = styled(ButtonWithLoading)`
  padding: 0.5rem 1.875rem;
  margin-top: 1rem;
  position: absolute;
  bottom: 1.5rem;
`;

const modalContent = css`
  margin-top: 1.5rem;
`;
const modalHeading = css`
  margin-bottom: 1rem;
`;

const closeIcon = css`
  cursor: pointer;
  font-size: 2rem;
  text-align: right;
  padding: 0.625rem;
  line-height: 0;
`;

const CarModel = ({ carData }) => {
  return (
    <CarModelContentWrapper>
      {carData?.nameOfCar && (
        <CarModelContent>
          {t('Name of car')}: <strong>{carData?.nameOfCar}</strong>
        </CarModelContent>
      )}
      {carData?.licensePlateNumber && (
        <CarModelContent>
          {t('Licenseplate Number')}:{' '}
          <strong>{carData?.licensePlateNumber}</strong>
        </CarModelContent>
      )}
      {carData?.modelYear && (
        <CarModelContent>
          {t('Model year')}: <strong>{carData?.modelYear}</strong>
        </CarModelContent>
      )}
      {carData?.gearboxText && (
        <CarModelContent>
          {t('Gearbox')}: <strong>{carData?.gearboxText}</strong>
        </CarModelContent>
      )}
      {carData?.aisEngineCode && (
        <CarModelContent>
          {t('Engine code')}: <strong>{carData?.aisEngineCode}</strong>
        </CarModelContent>
      )}
      {carData?.carType && (
        <CarModelContent>
          {t('Car type')}: <strong>{carData?.carType}</strong>
        </CarModelContent>
      )}
      {carData?.color && (
        <CarModelContent>
          {t('Color')}: <strong>{carData?.color}</strong>
        </CarModelContent>
      )}
      {carData?.bodyStyle && (
        <CarModelContent>
          {t('Body style')}: <strong>{carData?.bodyStyle}</strong>
        </CarModelContent>
      )}
      {carData?.fuelText && (
        <CarModelContent>
          {t('Fuel')}: <strong>{carData?.fuelText}</strong>
        </CarModelContent>
      )}
    </CarModelContentWrapper>
  );
};

const VehicleInformationModal = ({ hideTarget, carData }) => {
  return (
    <div>
      <p className={closeIcon} onClick={hideTarget}>
        x
      </p>
      <div className={modalContent}>
        <h3 className={modalHeading}>{t('Chosen vehicle')}</h3>
        <CarModel carData={carData} />
        <CloseModalButton onClick={hideTarget}>{t('Close')}</CloseModalButton>
      </div>
    </div>
  );
};

const CarModelSpecification = ({ carData }) => {
  return (
    <>
      <Above breakpoint="sm">
        <CarModel carData={carData} />
      </Above>
      <Below breakpoint="sm">
        <ModalTrigger
          target={({ hideTarget }) => (
            <VehicleInformationModal
              carData={carData}
              hideTarget={hideTarget}
            />
          )}
        >
          {({ showTarget }) => (
            <div>
              <ButtonWithLoading
                onClick={showTarget}
                className="secondary fill-width small"
              >
                {t('See chosen vechicle')}
              </ButtonWithLoading>
            </div>
          )}
        </ModalTrigger>
      </Below>
    </>
  );
};

export default CarModelSpecification;
