import { css } from 'linaria';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import ImportedMaxWidth from '../../../components/Layout/MaxWidth';
import ImportedSpinner from '../../../components/ui/Spinner';
import { theme } from '../../../components/Theme';

const searchFieldStyles = css`
  margin: 0;
  width: 100%;

  &[data-flight-searchfield] > * {
    display: flex;
    height: 100%;
    width: 100% !important;
    justify-content: center;

    input {
      font-family: 'Barlow';
      font-weight: 600;
      font-size: 1rem;
      line-height: 120%;
      border: 1px solid ${theme.colors.lightgrey};
      color: #1a202d;
      height: 100%;
      width: 100%;
      padding-left: 10px;

      ::placeholder {
        text-transform: none;
      }

      ${theme.below.md} {
        font-size: 1rem;
      }
    }
  }
  [data-flight-searchfield-cancel] {
    display: none;
  }
  [data-flight-searchfield-flyout] {
    text-align: left;
    top: 100%;

    > * {
      padding: 0.5rem;
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }
    li {
      border-top: 1px solid #e8e8e8;
    }
    a {
      color: black;
      padding: 0.5rem;
      display: block;
    }
  }
`;

const HeaderWrapper = styled('div')`
  background-color: ${theme.colors.abdLightGrey};
  font-family: 'Barlow';
`;

const MaxWidth = styled(ImportedMaxWidth)`
  ${theme.below.md} {
    padding: 0;
  }
`;

const RegNrContainer = styled('div')`
  margin: 30px 0;
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;

  ${theme.below.md} {
    margin-top: 0;
  }
`;

const ImageContainer = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const TextContainer = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  color: ${theme.colors.white};
  font-style: italic;
  padding: 0px 49px 0;

  ${theme.above.xl} {
    width: ${props => (props.columns === 2 ? '60%' : '40%')};
  }

  ${theme.below.md} {
    width: 100%;
    height: 474.79px;
  }

  ${theme.below.sm} {
    height: 474.79px;
  }
`;

const Title = styled('h1')`
  font-weight: 800;
  font-size: 3.75rem;
  line-height: 110%;
  margin-bottom: 20px;

  span {
    color: ${theme.colors.abdGreen};
  }

  ${theme.below.xl} {
    font-size: 3rem;
  }

  ${theme.below.sm} {
    font-size: 2.5rem;
  }
`;

const InfoText = styled('div')`
  font-size: 1rem;
  line-height: 140%;
  color: white;

  ${theme.below.lg} {
    font-size: 1rem;
  }
`;

const RegNumber = styled('div')`
  position: relative;
  z-index: 1;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 75%;
  max-height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${theme.colors.white};
  padding-right: 10px;
  height: 60px;
  border: 1px solid lightgray;
  width: 100%;
`;

const RegIcon = styled('div')`
  background-color: #062d93;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.white};
  font-size: 1rem;
  min-width: 60px;
  height: 50px;
  padding: 10px;
`;

const RegNr = styled('p')`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 120%;
  color: ${theme.colors.abdRegGrey};
  width: 90px;

  ${theme.below.xxl} {
    font-size: 1.2rem;
    width: 90px;
  }

  ${theme.below.xl} {
    width: 90px;
  }

  ${theme.below.md} {
    width: 90px;
  }

  ${theme.below.sm} {
    width: 110px;
  }

  ${theme.below.xs} {
    width: 150px;
  }
`;

const RegNrSuggestion = styled(Link)`
  position: absolute;
  top: 100%;
  left: 50px;
  right: 0;
  background: white;
  padding: 10px;
  text-transform: none;
  font-size: 1rem;
  border: 1px solid ${theme.colors.abdBadgeGrey};
  text-decoration: none;
  color: ${theme.colors.abdDarkGrey};

  ${theme.below.md} {
    left: 50px;
  }

  span {
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    font-style: italic;
  }

  :hover {
    background-color: ${theme.colors.abdBadgeGrey};
    text-decoration: underline;
  }
`;

const RegInfoInput = styled('input')`
  font-family: 'Barlow';
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 120%;
  width: 90%;
  border: 0;
  color: ${theme.colors.abdDarkGrey};

  :focus {
    outline: none;
  }

  ::placeholder {
    text-transform: none;
  }

  ${theme.below.md} {
    font-size: 1rem;
    height: 40px;
    width: 100%;
  }
`;

const SpanWrapper = styled('div')`
  display: flex;
  align-items: center;

  ${theme.below.md} {
    justify-content: center;
  }
`;

const SpanText = styled('span')`
  font-family: 'Barlow';
  font-weight: 700;
  font-size: 1.125rem;
  color: ${theme.colors.white};

  ${theme.below.md} {
    font-size: 1.125rem;
  }

  ${theme.below.sm} {
    font-size: 1rem;
  }
`;

const ButtonContainer = styled('div')`
  display: inline-block;

  ${theme.below.sm} {
    display: block;
  }
`;

const InputFieldsContainer = styled('form')`
  gap: 20px;
  position: relative;
  z-index: 1;
  margin-right: 32px;
  margin-left: 32px;

  input:focus {
    outline: none;
  }

  ${theme.below.lg} {
    padding: 20px 0rem 20px;
  }

  ${theme.below.md} {
    padding: 1rem 0rem 1rem;
    flex-direction: column;
  }

  ${theme.below.sm} {
    padding: 0rem;
  }
`;

const InputSearchFieldsContainer = styled(InputFieldsContainer)`
  padding: 0px;
`;

const SearchFieldContainer = styled('div')`
  position: relative;
  width: 550px;
  max-width: 550px;
  margin-left: 10px;

  ${theme.below.xl} {
    margin-top: 20px;
    margin-left: 0px;
  }
`;

const MessageContainer = styled('p')`
  position: absolute;
  right: 80px;
  color: white;
`;

const SearchIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #062d93;
  min-width: 60px;
  height: 50px;

  svg {
    path {
      stroke: white;
    }
  }
`;

const Spinner = styled(ImportedSpinner)`
  position: absolute;
  right: 28px;
  width: 15px;
  height: 15px;

  ${theme.above.md} {
    right: 32px;
    width: 20px;
    height: 20px;
  }

  circle.path {
    stroke: ${theme.colors.abdGreen};
  }
`;

const InnerContainer = styled('div')`
  padding: 30px;
  position: relative;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);

  width: 30%;
  height: 100%;

  ${theme.below.xl} {
    min-width: 350px;
  }

  ${theme.below.md} {
    min-width: 0px;
    background: none;
    backdrop-filter: none;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
  }

  ${theme.below.sm} {
    margin-top: 0px;
    margin-right: 0px;
    flex-direction: column;
  }
`;

const TextContainerMobile = styled('div')`
  margin-top: 50px;
`;

const TitleMobile = styled('h1')`
  font-weight: 800;
  font-size: 3.75rem;
  line-height: 110%;
  margin-bottom: 20px;
  color: white;

  span {
    color: ${theme.colors.abdGreen};
  }

  ${theme.below.xl} {
    font-size: 3rem;
  }

  ${theme.below.sm} {
    font-size: 2.625rem;
    font-style: italic;
    margin-bottom: 16px;
  }
`;

const LinkWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  padding: 8px 10px 10px 10px;
  border-bottom-left-radius: 10px;
`;

const ExternalLink = styled('a')`
  color: black;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const location = css`
  margin-right: 8.72px;
`;

const FilterListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  display: none;
`;

const Styled = {
  searchFieldStyles,
  HeaderWrapper,
  MaxWidth,
  RegNr,
  RegNrContainer,
  RegIcon,
  RegNumber,
  RegInfoInput,
  RegNrSuggestion,
  ImageContainer,
  TextContainer,
  Title,
  InfoText,
  ButtonContainer,
  InputFieldsContainer,
  InputSearchFieldsContainer,
  MessageContainer,
  SearchFieldContainer,
  SearchIconContainer,
  Spinner,
  SpanWrapper,
  SpanText,
  ExternalLink,
  LinkWrapper,
  InnerContainer,
  TextContainerMobile,
  TitleMobile,
  FilterListContainer,
  location
};

export default Styled;
