import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { useHistory, useLocation } from 'react-router';
import SearchAutoCompleteContainer from '../SearchPage/SearchAutoCompleteContainer';
import { theme } from '../Theme';
import { getFilterQueryString } from '../../helpers';
import useRecopartSearch from '../../hooks/useRecopartSearch';
import Styled from '../../elements/root/RegNrBlock/StyleAlt';
import { ReactComponent as SEicon } from '../../assets/svg/SEicon.svg';
import { ReactComponent as VerificationCheck } from '../../assets/svg/VerificationCheck.svg';
import { ReactComponent as InputError } from '../../svg/InputError.svg';
import SearchField from '../SearchPage/SearchField';
import AutocompleteQuery from '../Layout/Header/AutocompleteQuery.gql';
import { useMultiListFilter } from '../../elements/root/RegNrBlock/MultiListFilters';
import { useRegNrContext, useRegNrDispatch } from '../../contexts/regnr';
import SearchFieldOriginalParts from '../Forms/SearchFieldOriginalParts/SearchFieldOriginalParts';

const OuterContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;

  ${theme.below.xl} {
    flex-wrap: wrap;
  }
`;

const RegNrContainer = styled.div`
  width: 100%;
  display: flex;
  ${theme.below.sm} {
    flex-direction: column;
  }
`;

const SearchFieldContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-left: 1.25rem;
  ${theme.below.xl} {
    margin-left: 0;
    margin-top: 0.5rem;
  }
  ${theme.below.sm} {
    flex-direction: column;
  }
`;
const SearchFieldWrapper = styled('div')`
  flex: 1;
`;

const CancelButton = styled('button')`
  font-size: 0.875rem;
  border: 0;
  background: transparent;
  color: #878787;
  margin-left: 1rem;
`;

const buttonWrapper = css`
  position: relative;
  background-color: #6cc400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 3px;
  }

  :hover,
  :active {
    background-color: ${theme.colors.abdGreenHover} !important;
  }

  button {
    font-size: 0.875rem;
    font-weight: 700 !important;
    text-transform: uppercase;
    color: white;
    display: none;
  }

  .badge {
    position: absolute;
    pointer-events: none;
    color: white;
    font-size: 0.5rem;
  }

  ${theme.below.lg} {
    margin-top: 20px;
  }

  ${theme.below.sm} {
    width: calc(100% - 60px);
    margin-top: 20px;
  }
`;

const Button = styled('button')`
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  padding: 13px 20px 14px 20px;
  width: 100%;

  svg:hover {
    path {
      fill: ${theme.colors.abdGreen} !important;
    }
  }
`;
const verificationCheckIcon = css`
  width: 40px;
`;
const inputErrorIcon = css`
  width: 40px;
  transform: rotate(-45deg);
`;

const InfoDialog = styled('div')`
  height: 50px;
  max-width: 170px;
  background-color: #6cc400;
  color: white;
  font-size: 0.85rem;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  overflow-wrap: break-word;
  flex-shrink: 0;
  border-radius: 0px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #6cc400;
    transform: translateY(-50%);
  }

  ${theme.below.sm} {
    margin-top: 1rem;
    margin-left: 0;
    height: auto;
    width: 100%;
    left: 0;

    ::before {
      left: 50%;
      top: -20px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #6cc400;
      transform: translateX(-50%);
    }
  }
`;

const SearchRegNrOrProduct = ({ carData }) => {
  const {
    searchString: recoSearchString,
    setSearchString,
    handleSearchStringChange,
    data,
    loading
  } = useRecopartSearch();

  const history = useHistory();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useRegNrDispatch();
  const { id, manufacturer, model } = useRegNrContext();
  const { sendFilterQuery } = useMultiListFilter();

  useEffect(() => {
    if (carData) {
      sendFilterQuery({
        multiListFilters: [
          {
            id: '2:fits-car',
            values: [carData.carCodeGroupName, carData.carCodeName]
          }
        ]
      });
      setSearchString(carData?.licensePlateNumber);
    }
  }, [carData]);

  useEffect(() => {
    const manufacturerItem = manufacturer.items.find(
      item => item.value === data?.recopartCarModel?.carCodeGroupName
    );

    if (manufacturerItem) {
      sendFilterQuery({
        multiListFilters: [{ id, values: [manufacturerItem.value] }]
      });
      dispatch({
        type: 'addActiveFilterItem',
        filter: {
          ...manufacturer,
          activeItem: { ...manufacturerItem, label: manufacturerItem.value }
        }
      });
    }
  }, [data?.recopartCarModel]);

  useEffect(() => {
    const modelItem = model.items.find(
      item => item.value === data?.recopartCarModel?.carCodeName
    );

    if (modelItem) {
      dispatch({
        type: 'addActiveFilterItem',
        filter: {
          ...model,
          activeItem: { ...modelItem, label: modelItem.value }
        }
      });
    }
  }, [model.items, data?.recopartCarModel]);

  useEffect(() => {
    if (
      manufacturer?.activeItem?.value !==
      data?.recopartCarModel?.carCodeGroupName
    ) {
      setSearchString('');
    }
  }, [manufacturer.activeItem]);

  const handleFormSubmit = searchField => {
    const filter =
      manufacturer.activeItem || model.activeItem
        ? getFilterQueryString(2, manufacturer.activeItem, model.activeItem)
        : location.search;
    searchTerm
      ? searchField.triggerSearch(searchTerm)
      : history.push(`/bil?${filter}`);
    window.location.reload(true);
  };

  return (
    <SearchAutoCompleteContainer
      autocompleteQuery={AutocompleteQuery}
      carData={data?.recopartCarModel}
      manufacturer={manufacturer}
      model={model}
    >
      {searchField => {
        return (
          <form
            role="search"
            onSubmit={e => {
              e.preventDefault();
              handleFormSubmit(searchField);
            }}
          >
            <OuterContainer>
              <RegNrContainer>
                <Styled.RegNumber>
                  <Styled.RegIcon>
                    <SEicon />
                  </Styled.RegIcon>
                  <Styled.RegNr>ABC123</Styled.RegNr>
                  <Styled.RegInfoInput
                    type="search"
                    name="RegInfoInput"
                    id="RegInfoInput"
                    value={recoSearchString}
                    onChange={handleSearchStringChange}
                    placeholder={'Ange regnr'}
                    aria-label="Sök efter fordonsinfo med registreringsnummer"
                  />
                  {data?.recopartCarModel?.nameOfCar && (
                    <VerificationCheck className={verificationCheckIcon} />
                  )}
                  {data?.recopartCarModel?.error && (
                    <InputError className={inputErrorIcon} />
                  )}
                  {loading && <Styled.Spinner />}
                </Styled.RegNumber>
                <InfoDialog>
                  <p>Ange regnr för att hitta delar som passar ditt fordon.</p>
                </InfoDialog>
              </RegNrContainer>
              {data?.recopartCarModel?.nameOfCar && (
                <Styled.MessageContainer>
                  {data?.recopartCarModel.nameOfCar}
                </Styled.MessageContainer>
              )}
              {data?.recopartCarModel?.error && (
                <Styled.MessageContainer>
                  {data?.recopartCarModel?.errorMessage ||
                    'Vi kunde inte hitta någon bilmodell'}
                </Styled.MessageContainer>
              )}
              <div className={buttonWrapper}>
                <Button
                  className="button"
                  disabled={!manufacturer.activeItem && !model.activeItem}
                >
                  <span>Byt fordon</span>
                </Button>
              </div>
              <SearchFieldContainer>
                <SearchFieldWrapper>
                  <SearchFieldOriginalParts
                    searchField={searchField}
                    searchInputPlaceholder="Bildel/Artnr/Originalnr"
                    setSearchTerm={setSearchTerm}
                    carData={data.recopartCarModel}
                  />
                </SearchFieldWrapper>
                <InfoDialog>
                  <p>Eller sök på originalnummer.</p>
                </InfoDialog>
              </SearchFieldContainer>
            </OuterContainer>
          </form>
        );
      }}
    </SearchAutoCompleteContainer>
  );
};

SearchField.Cancel = CancelButton;

export default SearchRegNrOrProduct;
