import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

const ErrorMessageHandlerContainer = styled('div')`
  height: 50vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin-bottom: 20px;
  }

  a {
    color: black;
    text-decoration: underline;
  }
`;

const ErrorMessageHandler = () => {
  return (
    <ErrorMessageHandlerContainer>
      <div>
        <h2>Vi hittade inga produkter som matchade dina kriterier</h2>
        <p>
          Pröva att <Link to="/bil">söka igen</Link> eller skicka en förfrågan
          till{' '}
          <a href="mailto:kundservice@allabildelar.se">
            kundservice@allabildelar.se
          </a>{' '}
          för att få hjälp att hitta din bildel.
        </p>
        <p>
          Glöm inte att skriv bilens <b>registreringsnummer</b> och vilka{' '}
          <b>bildelar</b> du söker.
        </p>
      </div>
    </ErrorMessageHandlerContainer>
  );
};

export default ErrorMessageHandler;
