import React from 'react';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';
import { useMultiFilter } from '@jetshop/core/hooks/Filters/useMultiFilter';
import Select from '../../ui/Select';

export function MultiListFilters({ filters, onBrandChange }) {
  const multiListFilters = filters.filter(
    filter => filter.__typename === 'MultiListFilter'
  );

  return multiListFilters.map(multiListFilter => (
    <ErrorBoundary component={PassThrough} key={multiListFilter.id}>
      {multiListFilter.lists.map((listFilter, index) => (
        <MultiListFilter
          filter={listFilter}
          multiListFilter={multiListFilter}
          index={index}
          key={listFilter.id}
          onBrandChange={onBrandChange}
        />
      ))}
    </ErrorBoundary>
  ));
}

function MultiListFilter({ filter, multiListFilter, index, onBrandChange }) {
  const { apply } = useMultiFilter({
    filter: multiListFilter
  });

  const handleSelectChange = item => {
    if (item.resultCount > 0) {
      apply({ value: item.value, listIndex: index });
      if (filter.name === 'Tillverkare') {
        onBrandChange();
      }
    }
  };

  // Use the original filter name as the placeholder unless it's 'Tillverkare'
  const placeholderText =
    filter?.name === 'Tillverkare' ? 'Bilmärke' : filter?.name;

  const options = filter.items
    .filter(item => item.resultCount > 0)
    .map(item => ({
      ...item,
      label: item.text
    }));

  return (
    <Select
      instanceId={filter.id}
      placeholder={placeholderText}
      options={options}
      value={options.find(item => item.isActive) || ''}
      onChange={handleSelectChange}
    />
  );
}
